<template>
  <div class="me-login-box me-login-box-radius">
    <form :model="userForm">
      <div class="inputes">
        <input class="my-input" autocomplete="off" placeholder="输入授权码登录 回车/确认" :disabled="wrongCount >= 5 || wronglimit"
          v-model="userForm.password" @keyup.enter="login()" type="password" ref="password" />
        &nbsp;
        <input v-show="false">
        <button :disabled="wrongCount >= 5 || wronglimit" class="my-button" style="" :style="buttongstyle"
          @click.prevent="login()">确认</button>
      </div>
      <p class="warnmessage">{{ notemessage }}</p>
      <i class="notemessage">提示：授权码为部门全称拼音首字母小写</i>
    </form>
  </div>
</template>
<script>
import { root_serve_url, ax_get } from '../js/axreqs';
import store from '../pages/index/store';
import router from '../router';
import ck from '../js/cookieset';

export default {
  name: 'Login',
  data() {
    return {
      userForm: {
        password: ''
      },
      notemessage: "",
      wrongCount: 0,
      wronglimit: !!ck.getCookie("wronglimit"),
      wronglimitTime:  window._setting.wronglimitTime ?? 2,
    }
  },
  methods: {
    login() {
      const pwds = this.userForm.password;
      if (pwds.trim() === "") {
        this.$refs.password.style.borderColor = "red";
        this.notemessage = "授权码为空";
      } else if (pwds.length < 3) {
        this.$refs.password.style.borderColor = "red";
        this.notemessage = "授权码不正确";
        this.wrongCount++;
      } else {
        ax_get("/comGet/sdb", { item: 'name', value: "paibancode", table: "options" }, res => {
          if (res.data && res.data.length > 0) {
            const retrunValue = res.data[0]?.value;
            if (retrunValue && retrunValue.split(",").includes(pwds)) {
              this.notemessage = "";
              this.wrongCount = 0;
              store.commit("gologin")
              ck.setCookie("login-status", new Date().getTime(), 60 * 15);
              router.replace("/").catch(err => { console.log(err) })
            } else {
              this.notemessage = "授权码错误";
              this.wrongCount++;
              this.$refs.password.focus();
            }
          }
        }, root_serve_url)
      }
      return false;
      // let params = new URLSearchParams();
      // params.append('account', this.userForm.account);
      // params.append('password', this.userForm.password);
      // this.$axios.post('http://localhost:8888/user/login', params)
    },
    restoreInput() {
      this.wrongCount = 0;
      this.notemessage = "";
      this.$refs.password.focus();
      this.wronglimit=false;
    },
  },
  computed: {
    buttongstyle() {
      return {
        "background-color": this.wrongCount >= 5 || this.wronglimit ? "lightgray" : 'green'
      }

    },
  },
  watch: {
    wrongCount(val) {
      if (val >= 5) {
        this.notemessage = `授权码错误超过限定次数,锁定登录${this.wronglimitTime}分钟`
        setTimeout(this.restoreInput, 1000 * 60 * this.wronglimitTime)
        ck.setCookie("wronglimit", new Date().getTime(), this.wronglimitTime * 60)
      }
    },
  }
  , mounted() {
    const limitTime = ck.getCookie("wronglimit");
    if (limitTime) {
      const deltatime = this.wronglimitTime * 1000 * 60 - (new Date() - (limitTime * 1));
      this.notemessage = `授权码错误超过限定次数,锁定登录${this.wronglimitTime}分钟`
      setTimeout(this.restoreInput, deltatime);
    }
  }
}
</script>

<style scoped>
.me-login-box {
  position: absolute;
  width: 350px;
  background-color: lightcyan;
  margin-top: 150px;
  margin-left: -180px;
  left: 50%;
  padding: 30px;
}

.me-login-box-radius {
  border-radius: 10px;
}

.inputes {
  display: flex;
  flex-direction: row;
}


.my-input {
  width: 255px;
  height: 35px;
  border: 0.1px solid #797979;
  padding-left: 35px;
}

.my-button {
  width: 3rem;
  height: 2rem;
  /* background-color: #4CAF50; */
  color: white;
  border: none;
  border-radius: 20%;
  text-align: center;
  font-size: smaller;
  cursor: pointer;
}

.warnmessage {
  color: red;
  font-size: smaller;
}

.notemessage {
  font-size: smaller;
}
</style>